import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkaReklamy = () => {
  return (
    <>
      <Helmet>
        <title>Съемка рекламы заказать в Москве цена от студии Elandic</title>
        <meta name="description" content="Съемка рекламы заказать в Москве цена на сайте студии Elandic. Заказать профессиональные услуги съемки рекламы онлайн стоимость от видеопродакшена. " />
        <meta name="keywords" content="съемка рекламы, в москве, заказать, цена, студия" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемка рекламы'>
          <p>В условиях высокой конкуренции на рекламном рынке Москвы ключевым фактором успеха является креативность и качество визуального контента. Студия Elandic предлагает свои услуги по съемке рекламы, которая выделяется свежими идеями и профессиональным исполнением. Мы гарантируем, что ваша реклама не только достойно представит ваш бренд, но и привлечет внимание целевой аудитории.</p>

          <p>Наши услуги охватывают весь процесс создания рекламы – от разработки концепции и сценария до профессиональной съемки и постпродакшена. Сотрудничество со студией Elandic – это не только возможность получить высококачественный рекламный продукт, но и шанс работать с командой талантливых специалистов, которые воплотят ваши самые смелые идеи.</p>

          <p>Цена на съемку рекламы в Москве в нашей студии формируется индивидуально, в зависимости от объема и сложности работ. Мы предлагаем полный спектр услуг: от простых рекламных роликов до сложных многосерийных проектов для крупных брендов. Наши клиенты могут быть уверены, что получат продукт высшего качества по справедливой и прозрачной цене.</p>

          <p>Заказать съемку рекламы в студии Elandic просто – достаточно связаться с нами любым удобным способом. Наша команда всегда готова обсудить ваши идеи и предложить наилучшее решение для их реализации.</p>

          <p>Выбирая студию Elandic для съемки вашей рекламы в Москве, вы получаете качественный рекламный продукт, и гарантию того, что ваш бренд заметят. Мы стремимся к долгосрочному сотрудничеству и готовы сделать все возможное, чтобы ваше видение стало реальностью. Студия Elandic – это синоним качества и инновационности в съемке рекламы.</p>
        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkaReklamy);
